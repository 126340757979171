import React from "react";

const DailyVolume = ({ topTokens }) => {
  return (
    <div className="daily-volume">
      <h3>Top Daily Volume (24 hours)</h3>
      <ul>
        {topTokens.map((token, index) => (
          <li key={index} className="volume-item">
            <a
              href={`https://plonky.io/token/${token.tokenAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="token-name">
                <strong>{token.name} ({token.symbol})</strong>
              </div>
              <div className="token-details">
                <span>Price: ${parseFloat(token.price).toFixed(8)}</span>
                <span
                  className={`price-change ${
                    token.priceChange24h > 0 ? "positive" : "negative"
                  }`}
                >
                  {token.priceChange24h}%
                </span>
              </div>
              <div className="volume-metrics">
                <p>24h Volume: ${parseFloat(token.volume24h).toLocaleString()}</p>
                <p>Market Cap: ${parseFloat(token.marketCap).toLocaleString()}</p>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DailyVolume;
