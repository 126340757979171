import axios from "axios";

//const API_BASE_URL = "http://localhost:5001";
const API_BASE_URL = "https://plonkyredisapiserver.onrender.com";


// Fetch all tokens
export const fetchAllTokens = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/tokens`);
  return response.data;
};

// Fetch a single token by address
export const fetchTokenByAddress = async (address) => {
  const response = await axios.get(`${API_BASE_URL}/api/token/${address}`);
  return response.data;
};

