import React from "react";

const Header = ({ totalTokens, onSearch }) => {
  return (
    <header className="header">
      <h1>{totalTokens} Tokens Deployed</h1>
      <input
        type="text"
        placeholder="Search by token name, symbol, or requestor..."
        className="search-bar"
        onChange={(e) => onSearch(e.target.value)}
      />
      <div className="filters">
        <a
          href="https://app.plonky.io/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <button>Deploy Token</button>
        </a>
        <a
          href="https://app.plonky.io/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <button>Claim LP Rewards</button>
        </a>
        <a
          href="https://plonky.gitbook.io"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <button>Docs</button>
        </a>
      </div>
    </header>
  );
};

export default Header;
