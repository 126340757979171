import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import axios from "axios";

// ENS Registry and Reverse Registrar Addresses
const ENS_REGISTRY_ADDRESS = "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e";
const ENS_REVERSE_REGISTRAR_ADDRESS = "0x084b1c3C81545d370f3634392De611CaaBFf8148";

// ENS Registry ABI
const ENS_REGISTRY_ABI = [
  "function resolver(bytes32 node) external view returns (address)",
];

// ENS Resolver ABI
const ENS_RESOLVER_ABI = [
  "function name(bytes32 node) external view returns (string)",
  "function supportsInterface(bytes4 interfaceID) external view returns (bool)",
];

const TokenList = ({ tokens }) => {
  const [resolvedNames, setResolvedNames] = useState({});
  const [deployerDetails, setDeployerDetails] = useState({});
  const [failedFetches, setFailedFetches] = useState(new Set()); // Track failed fetches


 // console.log("deployerDetails",deployerDetails)
  

  useEffect(() => {
    const resolveNamesAndDetails = async () => {
      const names = { ...resolvedNames };
      const details = { ...deployerDetails };
      const provider = new ethers.providers.JsonRpcProvider(
        "https://snowy-cool-star.quiknode.pro/b75613801c4a8351bcd306e3119eee175c641c39"
      );

      for (const token of tokens) {
        const address = token.deployer;
        const addresstoken = token.tokenAddress;

        // Skip fetching if it's already a known failed fetch
        if (failedFetches.has(addresstoken)) {
     //     console.log(`Skipping failed fetch for ${addresstoken}`);
          continue;
        }

        // Skip fetching if details are already cached
        if (details[address]) {
      //    console.log(`Details already fetched for ${address}`);
          continue;
        }

        try {
   //       console.log("Fetching detail for", addresstoken);

          // Fetch deployer details from the API
          const response = await axios.get(
            `https://plonkyredisapiserver.onrender.com/api/deployerfarcaster/${addresstoken}`
          );

          const { display_name, pfp_url, username } = response.data;

      //    console.log("username",username)
      //    console.log("display_name",display_name)
      //    console.log("addresstoken",addresstoken)


      //    details[address] = { displayName: display_name, pfp: pfp_url, username: username };
          details[addresstoken] = { displayName: display_name, pfp: pfp_url, username: username };


          // Update deployer details state
          setDeployerDetails((prev) => ({
            ...prev,
            [addresstoken]: { displayName: display_name, pfp: pfp_url, username: username },
          }));
        } catch (error) {
          // If the error is a 404, add to failed fetches
          if (error.response?.status === 404) {
         //   console.warn(`404 error for ${addresstoken}, skipping future fetches`);
            setFailedFetches((prev) => new Set(prev).add(addresstoken));
          } else {
       //     console.error(`Error fetching details for ${addresstoken}:`, error);
          }

          // Attempt ENS resolution if API call fails
          if (!names[address]) {
            try {
              const ensName = await fetchEnsName(provider, address);
              const resolvedName = ensName || address;

              names[address] = resolvedName;
              setResolvedNames((prev) => ({ ...prev, [address]: resolvedName }));
            } catch (ensError) {
              console.error(
                `Error resolving ENS name for address ${address}:`,
                ensError
              );

              names[address] = address; // Fallback to raw address
              setResolvedNames((prev) => ({ ...prev, [address]: address }));
            }
          }
        }
      }
    };

    resolveNamesAndDetails();
  }, [tokens, deployerDetails, resolvedNames, failedFetches]);
  

  const fetchEnsName = async (provider, address) => {
    try {
      // Compute the reverse node
      const reverseNode = ethers.utils.namehash(
        `${address.slice(2).toLowerCase()}.addr.reverse`
      );

      // Create a contract instance for the ENS Registry
      const ensRegistry = new ethers.Contract(
        ENS_REGISTRY_ADDRESS,
        ENS_REGISTRY_ABI,
        provider
      );
      // Get the resolver address for the reverse node
      const resolverAddress = await ensRegistry.resolver(reverseNode);

      if (!resolverAddress || resolverAddress === ethers.constants.AddressZero) {
    //    console.log(`No resolver found for reverse node: ${reverseNode}`);
        return null; // No resolver found
      }

      // Create a contract instance for the ENS Resolver
      const ensResolver = new ethers.Contract(
        resolverAddress,
        ENS_RESOLVER_ABI,
        provider
      );

      // Skip 'supportsInterface' check and directly fetch the ENS name
      try {
        const ensName = await ensResolver.name(reverseNode);
        return ensName || null;
      } catch (nameError) {
        console.error(
          `Failed to fetch name from resolver: ${resolverAddress}`,
          nameError
        );
        return null;
      }
    } catch (error) {
      console.error("Error fetching ENS name:", error);
      return null;
    }
  };

  return (
    <div className="token-list">
      {tokens.map((token) => (
        <div className="token-card" key={token.tokenAddress}>
          <img
            src={token.imageUrl || "https://via.placeholder.com/50"}
            alt={token.name}
            className="token-logo"
          />
          <div>
            <h2>
              {token.name} ({token.symbol})
            </h2>
            <p>Address: {token.tokenAddress}</p>

<p>
  Requested by:{" "}
  {deployerDetails[token.tokenAddress] ? (
    <>
      <a
        href={`https://warpcast.com/${deployerDetails[token.tokenAddress].username || ""}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <img
          src={
            deployerDetails[token.tokenAddress].pfp ||
            "https://via.placeholder.com/20"
          }
          alt={deployerDetails[token.tokenAddress].displayName}
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            verticalAlign: "middle",
            marginRight: "5px",
          }}
        />
        {deployerDetails[token.tokenAddress].displayName || "Anonymous"}
      </a>
    </>
  ) : resolvedNames[token.deployer] ? (
    resolvedNames[token.deployer]
  ) : (
    token.deployer // Fallback to raw address immediately
  )}
</p>



            <p>Market Cap: ${token.marketCap}</p>
            <p>Liquidity: ${token.liquidity}</p>
            <p>24h Volume: ${token.volume24h}</p>
          </div>
          <div className="actions">
            <Link to={`/token/${token.tokenAddress}`}>Token Page</Link>

            <a
              href={`https://dexscreener.com/base/${token.pairAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              DexScreener
            </a>
            <a
              href={`https://basescan.org/address/${token.tokenAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              BaseScan
            </a>
            <button>Add to Wallet</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TokenList;
