import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import TokenPage from "./pages/TokenPage"; // Import the TokenPage component
import "./App.css";
import 'process';



function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Route for Homepage */}
          <Route path="/" element={<Homepage />} />

          {/* Route for Token Page */}
          <Route path="/token/:address" element={<TokenPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
