import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ethers } from "ethers";
import axios from "axios";
import { fetchTokenByAddress } from "../api"; // Assume this is in api.js
import "./TokenPage.css";

// ENS Registry and Resolver Constants
const ENS_REGISTRY_ADDRESS = "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e";
const ENS_REGISTRY_ABI = [
  "function resolver(bytes32 node) external view returns (address)",
];
const ENS_RESOLVER_ABI = [
  "function name(bytes32 node) external view returns (string)",
  "function supportsInterface(bytes4 interfaceID) external view returns (bool)",
];

const TokenPage = () => {
  const { address } = useParams(); // Get token address from URL params
  const [token, setToken] = useState(null);
  const [deployerDetails, setDeployerDetails] = useState({});
  const [resolvedName, setResolvedName] = useState(null);
  const [failedFetches, setFailedFetches] = useState(new Set());
 // console.log("deployerDetails",deployerDetails)

  useEffect(() => {
    const loadToken = async () => {
      const data = await fetchTokenByAddress(address);
      setToken(data);
    };

    loadToken();
  }, [address]);

  useEffect(() => {
    const resolveNameAndDetails = async () => {
      if (!token) return;

      const provider = new ethers.providers.JsonRpcProvider(
        "https://snowy-cool-star.quiknode.pro/b75613801c4a8351bcd306e3119eee175c641c39"
      );
      const deployer = token.deployer;

      // Skip if already resolved
      if (deployerDetails[deployer]) return;

      try {
        const response = await axios.get(
          `https://plonkyredisapiserver.onrender.com/api/deployerfarcaster/${address}`
        );

        const { display_name, pfp_url, username, text } = response.data;
        setDeployerDetails((prev) => ({
          ...prev,
          [deployer]: { displayName: display_name, pfp: pfp_url, username, text : text },
        }));
      } catch (error) {
        if (error.response?.status === 404) {
          setFailedFetches((prev) => new Set(prev).add(address));
        } else {
          console.error(`Error fetching deployer details:`, error);
        }

        // Attempt ENS resolution if API fails
        try {
          const ensName = await fetchEnsName(provider, deployer);
          setResolvedName(ensName || deployer); // Set ENS name or fallback to address
        } catch (ensError) {
          console.error(`Error resolving ENS name for ${deployer}:`, ensError);
          setResolvedName(deployer); // Fallback to raw address
        }
      }
    };

    resolveNameAndDetails();
  }, [token, deployerDetails, failedFetches]);

  const fetchEnsName = async (provider, address) => {
    try {
      const reverseNode = ethers.utils.namehash(
        `${address.slice(2).toLowerCase()}.addr.reverse`
      );

      const ensRegistry = new ethers.Contract(
        ENS_REGISTRY_ADDRESS,
        ENS_REGISTRY_ABI,
        provider
      );
      const resolverAddress = await ensRegistry.resolver(reverseNode);

      if (!resolverAddress || resolverAddress === ethers.constants.AddressZero) {
        return null; // No resolver found
      }

      const ensResolver = new ethers.Contract(
        resolverAddress,
        ENS_RESOLVER_ABI,
        provider
      );
      const ensName = await ensResolver.name(reverseNode);
      return ensName || null;
    } catch (error) {
      console.error("Error fetching ENS name:", error);
      return null;
    }
  };

  const addToWallet = async () => {
    if (!window.ethereum) {
      alert("MetaMask is not installed. Please install it to add the token to your wallet.");
      return;
    }

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: token.tokenAddress,
            symbol: token.symbol,
            decimals: 18,
            image: token.imageUrl || "https://via.placeholder.com/50",
          },
        },
      });
      alert(`${token.symbol} has been added to your wallet!`);
    } catch (error) {
      console.error("Failed to add token to wallet:", error);
      alert("Failed to add token to wallet. Please try again.");
    }
  };

  if (!token) {
    return <div>Loading...</div>;
  }

  return (
    <div className="token-page">
      {/* Back to All Tokens Button */}
      <Link to="/" className="back-to-tokens">
        ← Back to All Tokens
      </Link>

      {/* Token Header */}
      <div className="token-header">
        <img
          src={token.imageUrl || "https://via.placeholder.com/50"}
          alt={token.name}
          className="token-logo"
        />
        <div className="token-details">
          <h1>
            {token.name} ({token.symbol})
          </h1>
          <p>Address: {token.tokenAddress}</p>
          <p>
            Requested by:{" "}
            {deployerDetails[token.deployer] ? (
              <>
                <a
                  href={`https://warpcast.com/${deployerDetails[token.deployer].username || ""}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <img
                    src={
                      deployerDetails[token.deployer].pfp ||
                      "https://via.placeholder.com/20"
                    }
                    alt={deployerDetails[token.deployer].displayName}
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      verticalAlign: "middle",
                      marginRight: "5px",
                    }}
                  />
                  {deployerDetails[token.deployer].displayName || "Anonymous"}
                </a>
              </>
            ) : resolvedName ? (
              resolvedName
            ) : (
              token.deployer
            )}
          </p>

           {/* Conditionally render the description */}
    {deployerDetails[token.deployer]?.text && (
      <p>
        <span style={{ fontStyle: "italic", color: "#666" }}>
          {deployerDetails[token.deployer].text}
        </span>
      </p>
    )}
  
          <p>Market Cap: ${parseFloat(token.marketCap).toLocaleString()}</p>
          <div className="actions">
            <button onClick={addToWallet}>Add to Wallet</button>
         
            <a
              href={`https://basescan.org/address/${token.tokenAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              BaseScan
            </a>
            <a
              href={`https://dexscreener.com/base/${token.pairAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              DexScreener
            </a>
            <a
              href={`https://app.plonky.io/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Deploy and Earn LP Fee
            </a>
          </div>
        </div>
      </div>

      {/* Embeds Section */}
      <div className="embeds-section">
        {/* DexScreener Embed */}
        <div className="dexscreener-embed">
          <iframe
            src={`https://dexscreener.com/base/${token.pairAddress}?embed=1&loadChartSettings=0&trades=0&tabs=0&info=0&chartLeftToolbar=0&chartDefaultOnMobile=1&chartTheme=dark&theme=dark&chartStyle=0&chartType=usd&interval=15`}
            title="DexScreener Chart"
            style={{
              width: "100%",
              height: "600px",
              border: "0",
              borderRadius: "8px",
            }}
          ></iframe>
        </div>

        {/* Uniswap Swap Embed */}
        <div className="uniswap-swap-box">
          <iframe
            src={`https://app.uniswap.org/#/swap?exactField=input&outputCurrency=${token.tokenAddress}&chain=base`}
            title="Uniswap Swap Box"
            frameBorder="0"
            allow="clipboard-write"
            style={{
              width: "100%",
              height: "600px",
              border: "0",
              borderRadius: "8px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TokenPage;
