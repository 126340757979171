import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import TokenList from "../components/TokenList";
import DailyVolume from "../components/DailyVolume";
import { fetchAllTokens } from "../api";

const Homepage = () => {
  const [tokens, setTokens] = useState([]);
  const [filteredTokens, setFilteredTokens] = useState([]);
  const [topTokens, setTopTokens] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const tokensPerPage = 50; // Show 50 tokens per page

  useEffect(() => {
    const loadTokens = async () => {
      const allTokens = await fetchAllTokens();
      setTokens(allTokens);
      setFilteredTokens(allTokens);

      // Sort top tokens by volume for "Top Daily Volume" section
      const sortedTokens = [...allTokens].sort((a, b) => b.volume24h - a.volume24h);
      setTopTokens(sortedTokens.slice(0, 5));
    };

    loadTokens();
  }, []);

  useEffect(() => {
    const filtered = tokens.filter(
      (token) =>
        token.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        token.symbol.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredTokens(filtered);
    setCurrentPage(1); // Reset to first page after search
  }, [searchQuery, tokens]);

  // Optional: If you still want to fetch again or do filtering after initial load, do it here.
  // Currently you have two loadTokens effects. Consider removing the second one if not needed.
  
  // Calculate indices for current page
  const indexOfLastToken = currentPage * tokensPerPage;
  const indexOfFirstToken = indexOfLastToken - tokensPerPage;
  const currentTokens = filteredTokens.slice(indexOfFirstToken, indexOfLastToken);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredTokens.length / tokensPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="homepage">
      <Header totalTokens={tokens.length} onSearch={setSearchQuery} />
      <div className="main-content">
        <DailyVolume topTokens={topTokens} />
        <TokenList tokens={currentTokens} />
    


      </div>

              {/* Pagination Controls */}
              <div className="pagination-controls">
  <button onClick={handlePrevPage} disabled={currentPage === 1}>
    Previous
  </button>
  <span> Page {currentPage} of {totalPages} </span>
  <button onClick={handleNextPage} disabled={currentPage === totalPages}>
    Next
  </button>
</div>

    </div>
  );
};

export default Homepage;
